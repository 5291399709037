import React, { FC, ReactElement } from 'react';

import { useMediaQuery } from '@material-ui/core';

import styles from './styles.module.scss';

import { TableCell } from 'components';
import { MemberAddressesList } from './components';

import { ICoalitionItemsProps, ICoalitionMemberData } from 'types/interfaces';

import { COALITIONS_PARAMS } from '../../constants';
import classNames from 'classnames';

export const VotingCoalitionsItems: FC<ICoalitionItemsProps> = ({
    timesVotedTogether,
    members,
    percentOfAllProposals,
    rank,
    sortedParamName,
}: ICoalitionItemsProps): ReactElement => {
    const isTablet = useMediaQuery('(max-width:767.98px)');

    return (
        <div className={styles.tableRow}>
            <div className={styles.number}>{rank}</div>
            <div className={classNames(styles.coalitions)}>
                {members?.map((member: ICoalitionMemberData) => (
                    <div key={member.address} className={styles.coalitionItem}>
                        <MemberAddressesList
                            address={member.address}
                            name={member.name || member.address}
                            avatar={member.avatar}
                        />
                    </div>
                ))}
            </div>
            {isTablet ? (
                <div className={styles.memberStats}>
                    <div>
                        <h6>times voted together</h6>
                        <p>{timesVotedTogether}</p>
                    </div>
                    <div>
                        <h6>% of all proposals</h6>
                        <p>{percentOfAllProposals.toFixed(1)}</p>
                    </div>
                </div>
            ) : (
                <>
                    <TableCell
                        className={styles.votedTogether}
                        isHighlighted={sortedParamName === COALITIONS_PARAMS.VOTED_TOGETHER_PARAM}
                    >
                        <span>{timesVotedTogether}</span>
                    </TableCell>
                    <TableCell
                        className={styles.percentage}
                        isHighlighted={sortedParamName === COALITIONS_PARAMS.PROPOSALS_PERCENT_PARAM}
                    >
                        <span>{percentOfAllProposals.toFixed(1)}</span>
                    </TableCell>
                </>
            )}
        </div>
    );
};
