import React, { FC, ReactElement, useState } from 'react';

import { useMediaQuery } from '@material-ui/core';

import styles from './styles.module.scss';

import { TableDataNotFound, SearchField, Loader } from 'components';
import { TableHeader, VotingCoalitionsItems } from './components';

import { coalitionsTableSearch } from './utils';

import { useAppSelector } from 'store';
import { tableDataSort } from 'helpers';

import { ICoalitionsTableData, ISortedParam } from 'types/interfaces';

export const VotingCoalitionsTable: FC = (): ReactElement => {
    const { organizationCoalitionsData, coalitionsDataLoading } = useAppSelector(({ organization }) => organization);
    const isTablet = useMediaQuery('(max-width:767.98px)');

    const [tabSearchValue, setTabSearchValue] = useState<string>('');
    const [sortedParam, setSortedParam] = useState<ISortedParam>({
        paramName: '',
        descending: true,
    });

    const sortByParam = (param: string) => {
        setSortedParam({
            paramName: param,
            descending: sortedParam.paramName === param ? !sortedParam.descending : true,
        });
    };

    const coalitionsSearchData = coalitionsTableSearch(
        tableDataSort([...organizationCoalitionsData], sortedParam),
        tabSearchValue,
    );

    return (
        <div className={styles.tableContent}>
            <div className={styles.tableOptions}>
                <h4 className={styles.title}>Voting Coalitions</h4>
                {!isTablet && (
                    <SearchField forTable={true} inputValue={tabSearchValue} setInputValue={setTabSearchValue} />
                )}
            </div>

            {coalitionsDataLoading && coalitionsSearchData.length === 0 && (
                <div className={styles.loaderBox}>
                    <Loader size={40} className={styles.membersLoader} />
                </div>
            )}

            {!coalitionsDataLoading && coalitionsSearchData.length === 0 && <TableDataNotFound forTable />}

            {coalitionsSearchData.length > 0 && (
                <div className={styles.tableWrapper}>
                    <div className={styles.tableContainer}>
                        {!isTablet && <TableHeader sortedParamName={sortedParam.paramName} sortByParam={sortByParam} />}

                        {coalitionsSearchData.map((coalition: ICoalitionsTableData, index: number) => (
                            <VotingCoalitionsItems
                                key={coalition.names}
                                {...coalition}
                                rank={index + 1}
                                sortedParamName={sortedParam.paramName}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};
