import React, { FC, ReactElement } from 'react';

import { HeaderItemWithSorting } from 'components';

import styles from './styles.module.scss';

import { COALITIONS_PARAMS } from '../../constants';

import { ITableHeaderWithSortingProps } from 'types/interfaces';

export const TableHeader: FC<ITableHeaderWithSortingProps> = ({
    sortedParamName,
    sortByParam,
}: ITableHeaderWithSortingProps): ReactElement => {
    return (
        <div className={styles.tableHeader}>
            <div className={styles.number}>
                <div>
                    <span>#</span>
                </div>
            </div>
            <div className={styles.coalitions}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name="coalitions"
                    sortedParamName={sortedParamName}
                    paramName={COALITIONS_PARAMS.COALITIONS_PARAM}
                />
            </div>
            <div className={styles.votedTogether}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name={`times voted together`}
                    sortedParamName={sortedParamName}
                    paramName={COALITIONS_PARAMS.VOTED_TOGETHER_PARAM}
                />
            </div>
            <div className={styles.percentage}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name={` % of all proposals`}
                    sortedParamName={sortedParamName}
                    paramName={COALITIONS_PARAMS.PROPOSALS_PERCENT_PARAM}
                />
            </div>
        </div>
    );
};
