import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { Loader } from 'components';
import { ActivityTable, VotingTable } from './components';

import { useAppSelector } from 'store';

export const ActivityVotingTables: FC = (): ReactElement => {
    const { activityDataLoading } = useAppSelector(({ organization }) => organization);

    return (
        <div className={styles.tablesWrapper}>
            <h4>Activity</h4>
            <div className={styles.tableWrapper}>
                <div className={styles.tableContainer}>
                    {activityDataLoading ? <Loader className={styles.loaderBox} size={50} /> : <ActivityTable />}
                </div>
            </div>
            <h4 className={styles.votingTitle}>Voting</h4>
            <div className={styles.tableWrapper}>
                <div className={styles.tableContainer}>
                    {activityDataLoading ? <Loader className={styles.loaderBox} size={50} /> : <VotingTable />}
                </div>
            </div>
        </div>
    );
};
