import { FormatUtils, LinkUtils } from 'utils';

import { ICommonTableHeader, ICommonTableHeaderSettings, IRowCellLinkData } from 'components/CommonTable/types';
import { IDaoTreasuryUpdatedAt, ISelectOptions, ISortingParams } from 'types/interfaces';
import { DAO_TREASURY_INVESTMENTS_TABLE_PARAMS } from './constants';

interface IBaseData {
    id: string;
    organizationId: string;
    title: string;
}

type IIndividualOrganizationTableData<T> = T & IRowCellLinkData;

const getIndividualOrganizationSelectData = <T extends IBaseData>(data: T[]): ISelectOptions[] =>
    data.map((items: T) => ({
        id: items.id || items.organizationId,
        name: items.title,
    }));

const getIndividualOrganizationTableData = <T extends IBaseData>(data: T[]): IIndividualOrganizationTableData<T>[] =>
    data.map((currentValues: T) => ({
        ...currentValues,
        linkData: {
            link: LinkUtils.createDynamicLink(currentValues.id || currentValues.organizationId, 'organization'),
            title: currentValues.title || currentValues.organizationId,
        },
    }));

const addSettingsToTableHeaderData = <T>(
    data: ICommonTableHeader<T, DAO_TREASURY_INVESTMENTS_TABLE_PARAMS>[],
    settings: ICommonTableHeaderSettings,
): ICommonTableHeader<T, DAO_TREASURY_INVESTMENTS_TABLE_PARAMS>[] =>
    data.map((item) => ({
        ...item,
        settings,
    }));

const convertOrderDataToQueryParam = (sortingData: ISortingParams): { [key: string]: string } => ({
    orderBy: FormatUtils.getSnakeCaseText(sortingData.paramName),
    order: sortingData.order.toLowerCase(),
});

const getLastUpdateDate = <T extends IDaoTreasuryUpdatedAt>(data?: T[]): string | undefined => data?.[0]?.updatedAt;

export {
    getIndividualOrganizationSelectData,
    getIndividualOrganizationTableData,
    addSettingsToTableHeaderData,
    convertOrderDataToQueryParam,
    getLastUpdateDate,
};
