import React, { FC, ReactElement, useMemo } from 'react';

import { Link } from 'react-router-dom';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { TableCell, TopTokensItems, ImageLoader, TooltipWrapper } from 'components';
import { AumTooltip } from './components';

import { ORGANIZATIONS_PARAMS } from 'constants/organizationsSorting';
import { DASH_DASH, DATA_NOT_FOUND } from 'constants/dataNotFound';
import { IMAGE_TYPES } from 'constants/imageTypes';

import { FormatUtils, LinkUtils } from 'utils';
import {
    memberIcon,
    votesIcon,
    proposalIcon,
    defaultOrganizationPlaceholderImage,
    defaultTokenPlaceholderImage,
} from 'assets';
import { useStyles } from './components/AumTooltip/styles';

import { IOrganizationItemsProps, IOrganizationTokenItems } from 'types/interfaces';

const MAX_TOKENS_ICONS = 5;

export const OrganizationItems: FC<IOrganizationItemsProps> = ({
    rank,
    organizationId,
    activeMembers,
    logo,
    daoName,
    totalValueUSD,
    totalNumMembers,
    totalNumProposals,
    totalNumVotes,
    tokens,
    sortedParamName,
    change1WeekPercentage,
    chainLogo,
    mainTreasuryTitle,
    aum = {},
}: IOrganizationItemsProps): ReactElement => {
    const classes = useStyles();

    const organizationLogo = useMemo(
        () =>
            LinkUtils.isValidUrl(logo)
                ? logo
                : LinkUtils.getDaoLogoLinkFromBucket(logo, defaultOrganizationPlaceholderImage),
        [logo],
    );

    const governanceTokenHoldersCount = useMemo(
        () =>
            !FormatUtils.checkIfDataNotExist(totalNumMembers)
                ? FormatUtils.formatNumberShort(totalNumMembers, 1, true)
                : DATA_NOT_FOUND,
        [totalNumMembers],
    );

    const activeMembersCount = useMemo(
        () =>
            !FormatUtils.checkIfDataNotExist(activeMembers)
                ? FormatUtils.formatNumberShort(activeMembers as number, 1, true)
                : DATA_NOT_FOUND,
        [activeMembers],
    );

    const proposalsCount = useMemo(
        () =>
            !FormatUtils.checkIfDataNotExist(totalNumProposals)
                ? FormatUtils.formatNumberShort(totalNumProposals, 1, true)
                : DATA_NOT_FOUND,
        [totalNumProposals],
    );

    const votesCount = useMemo(
        () =>
            !FormatUtils.checkIfDataNotExist(totalNumVotes)
                ? FormatUtils.formatNumberShort(totalNumVotes as number, 1, true)
                : DATA_NOT_FOUND,
        [totalNumVotes],
    );

    const totalTreasury = useMemo(
        () =>
            !FormatUtils.checkIfDataNotExist(totalValueUSD)
                ? `$${FormatUtils.formatNumberShort(totalValueUSD as number, 1, true)}`
                : DATA_NOT_FOUND,
        [totalValueUSD],
    );

    const organizationTokens = useMemo(() => tokens, [tokens, organizationId]);

    return (
        <Link to={LinkUtils.createDynamicLink(organizationId, 'organization')}>
            <div className={styles.tableRow}>
                <div className={styles.mainRowInfo}>
                    <div className={styles.rank}>
                        <span>{rank}</span>
                    </div>
                    <TableCell
                        className={styles.organization}
                        isHighlighted={sortedParamName === ORGANIZATIONS_PARAMS.ORGANIZATION_PARAM}
                    >
                        <ImageLoader
                            src={organizationLogo}
                            className={styles.organizationLogo}
                            imageType={IMAGE_TYPES.ORGANIZATION_IMAGE}
                        />
                        <span>{daoName}</span>
                    </TableCell>
                    <TableCell
                        className={styles.treasury}
                        isHighlighted={sortedParamName === ORGANIZATIONS_PARAMS.TREASURY_PARAM}
                    >
                        <TooltipWrapper
                            title={<AumTooltip liquidValue={aum.liquid?.value} vestingValue={aum.vesting?.value} />}
                            tooltipClass={classes.tooltip}
                            arrowClass={classes.arrow}
                        >
                            <span className={styles.treasuryValue}>{totalTreasury}</span>
                        </TooltipWrapper>
                    </TableCell>
                    <TableCell
                        className={styles.changesPerDay}
                        isHighlighted={sortedParamName === ORGANIZATIONS_PARAMS.CHANGES_PER_DAY}
                    >
                        {!FormatUtils.checkIfDataNotExist(change1WeekPercentage) && change1WeekPercentage !== DASH_DASH ? (
                            <>
                                <ArrowUpwardIcon
                                    className={classNames(styles.arrowIcon, {
                                        [styles.arrowIconNegative]: (change1WeekPercentage as number) < 0,
                                    })}
                                />
                                <span className={styles.percentageValue}>
                                    {FormatUtils.formatNumberShort(change1WeekPercentage as number, 1)}%
                                </span>
                            </>
                        ) : (
                            <span className={styles.percentageValue}>
                                {change1WeekPercentage ? DASH_DASH : DATA_NOT_FOUND}
                            </span>
                        )}
                    </TableCell>
                    <div className={styles.topTokens}>
                        {organizationTokens?.length ? (
                            <TopTokensItems
                                tokens={organizationTokens?.map(
                                    ({
                                        token_address,
                                        token_icon,
                                        token_symbol,
                                        token_price,
                                    }: IOrganizationTokenItems) => ({
                                        tokenAddress: token_address + token_price,
                                        logo: token_icon || defaultTokenPlaceholderImage,
                                        symbol: token_symbol,
                                        name: token_symbol,
                                    }),
                                )}
                                showMoreIcon={organizationTokens?.length > MAX_TOKENS_ICONS}
                            />
                        ) : (
                            <span className={styles.tokensNotFound}>{DATA_NOT_FOUND}</span>
                        )}
                    </div>
                    <TableCell
                        className={styles.mainTreasuryChain}
                        isHighlighted={sortedParamName === ORGANIZATIONS_PARAMS.MAIN_CHAIN_TITLE}
                    >
                        {mainTreasuryTitle ? (
                            <TooltipWrapper title={mainTreasuryTitle}>
                                <div className={styles.chainLogoWrapper}>
                                    <ImageLoader
                                        src={chainLogo}
                                        className={styles.chainLogo}
                                        imageType={IMAGE_TYPES.TOKEN_IMAGE}
                                    />
                                </div>
                            </TooltipWrapper>
                        ) : (
                            <span className={styles.tokensNotFound}>{DATA_NOT_FOUND}</span>
                        )}
                    </TableCell>
                    <TableCell
                        className={styles.potentialVoters}
                        isHighlighted={sortedParamName === ORGANIZATIONS_PARAMS.VOTERS_PARAM}
                    >
                        <img src={memberIcon} className={styles.memberIcon} alt="" />
                        <span>{governanceTokenHoldersCount}</span>
                    </TableCell>
                    <TableCell
                        className={styles.activeMembers}
                        isHighlighted={sortedParamName === ORGANIZATIONS_PARAMS.MEMBERS_PARAM}
                    >
                        <img src={memberIcon} className={styles.memberIcon} alt="" />
                        <span>{activeMembersCount}</span>
                    </TableCell>
                    <TableCell
                        className={styles.proposals}
                        isHighlighted={sortedParamName === ORGANIZATIONS_PARAMS.PROPOSALS_PARAM}
                    >
                        <img src={proposalIcon} className={styles.proposalIcon} alt="" />
                        <span>{proposalsCount}</span>
                    </TableCell>
                    <TableCell
                        className={styles.votes}
                        isHighlighted={sortedParamName === ORGANIZATIONS_PARAMS.VOTES_PARAM}
                    >
                        <img src={votesIcon} className={styles.votesIcon} alt="" />
                        <span>{votesCount}</span>
                    </TableCell>
                </div>
            </div>
        </Link>
    );
};
