import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { HeaderItemWithSorting } from 'components';

import { CURRENCIES_PARAMS } from '../../constants';

import { ITableHeaderWithSortingProps } from 'types/interfaces';

export const TableHeader: FC<ITableHeaderWithSortingProps> = ({
    sortedParamName,
    sortByParam,
}: ITableHeaderWithSortingProps): ReactElement => (
    <div className={styles.tableHeader}>
        <div className={styles.titleWrapper}>
            <h4 className={styles.tableTitle}>Currencies</h4>
        </div>
        <div className={styles.headerItems}>
            <div className={styles.rank}>
                <div>
                    <span>rank</span>
                </div>
            </div>
            <div className={styles.tokenInfo}>
                <div>
                    <span>token & chain</span>
                </div>
            </div>
            <div className={styles.currencyValue}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name="USD value / percentage"
                    sortedParamName={sortedParamName}
                    paramName={CURRENCIES_PARAMS.USD_VALUE_PARAM}
                />
            </div>
            <div className={styles.tokenBalance}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name="token balance"
                    sortedParamName={sortedParamName}
                    paramName={CURRENCIES_PARAMS.BALANCE_PARAM}
                />
            </div>
        </div>
    </div>
);
