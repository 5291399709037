import { ITabs_Types } from 'types/interfaces';

export const FINANCE = 'finance';
export const GOVERNANCE = 'governance';
export const MEMBERS = 'members';
export const COALITIONS = 'coalitions';
export const RESEARCH = 'dig_deeper';
const RESEARCH_NAME = 'dig deeper';

export const ORGANIZATION_DATA_TABS: ITabs_Types = {
    [FINANCE]: FINANCE,
    [GOVERNANCE]: GOVERNANCE,
    [MEMBERS]: MEMBERS,
    [COALITIONS]: COALITIONS,
    [RESEARCH]: RESEARCH_NAME,
};

export const ORGANIZATION_DATA = 'organization_data';
export const DISCUSSIONS = 'brainstorm';
const ORGANIZATION_DATA_NAME = 'organization data';

export const ORGANIZATION_TABS: ITabs_Types = {
    [ORGANIZATION_DATA]: ORGANIZATION_DATA_NAME,
    [DISCUSSIONS]: DISCUSSIONS,
};
