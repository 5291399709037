import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { ActivityVotingTables, ProposalsGraph, DecisionsTable } from './components';

export const OrganizationGovernance: FC = (): ReactElement => (
    <div className={styles.container}>
        <div className={styles.content}>
            <div className={styles.mainInfoContent}>
                <ProposalsGraph />
                <ActivityVotingTables />
            </div>

            <DecisionsTable />
        </div>
    </div>
);
