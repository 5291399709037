import { ITableTabs } from 'types/interfaces';

const tabItems: ITableTabs = {
    title: 'Voting Coalitions',
    tabsList: [
        { value: 'topCouples', text: 'top 10', id: 1 },
        { value: 'topTrios', text: 'trios', id: 2 },
        { value: 'topFours', text: 'fours', id: 3 },
        { value: 'topFives', text: 'fives', id: 4 },
    ],
};

enum COALITIONS_PARAMS {
    COALITIONS_PARAM = 'names',
    VOTED_TOGETHER_PARAM = 'timesVotedTogether',
    PROPOSALS_PERCENT_PARAM = 'percentOfAllProposals',
}

export { tabItems, COALITIONS_PARAMS };
