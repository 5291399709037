import React, { FC, ReactElement, useContext } from 'react';

import {
    OrganizationFinance,
    OrganizationGovernance,
    OrganizationMembers,
    OrganizationCoalitions,
    OrganizationResearch,
    TabsController,
} from './components';

import { FINANCE, GOVERNANCE, MEMBERS, COALITIONS, RESEARCH } from 'constants/organizationTabs';

import { OrganizationContext } from 'context/organizationContext';

export const OrganizationData: FC = (): ReactElement => {
    const { organizationDataTabSelected } = useContext(OrganizationContext);

    const renderOrganizationDataComponent = () => {
        switch (organizationDataTabSelected) {
            case FINANCE:
                return <OrganizationFinance />;

            case GOVERNANCE:
                return <OrganizationGovernance />;

            case MEMBERS:
                return <OrganizationMembers />;

            case COALITIONS:
                return <OrganizationCoalitions />;

            case RESEARCH:
                return <OrganizationResearch />;

            default:
                return <OrganizationFinance />;
        }
    };

    return (
        <>
            <TabsController />
            {renderOrganizationDataComponent()}
        </>
    );
};
