import React, { FC, ReactElement } from 'react';

import { useMediaQuery } from '@material-ui/core';

import moment from 'moment';

import styles from './styles.module.scss';

import { Loader, TableDataNotFound } from 'components';
import { ProposalsBarChart } from './components';

import { DATA_NOT_FOUND } from 'constants/dataNotFound';

import { useAppSelector } from 'store';

export const ProposalsGraph: FC = (): ReactElement => {
    const {
        organizationActivityData: { monthlyActivity },
        activityDataLoading,
    } = useAppSelector(({ organization }) => organization);
    const isMobile = useMediaQuery('(max-width: 575.98px)');

    return (
        <div className={styles.graphWrapper}>
            <div className={styles.header}>
                <span className={styles.title}>Monthly proposals and votes</span>
            </div>

            <div className={styles.proposalsGraphCard}>
                <div className={styles.proposalsGraphCardWrapper}>
                    {activityDataLoading && <Loader className={styles.loaderBox} size={50} />}

                    <div className={styles.wrapper}>
                        <div className={styles.graphInfoContent}>
                            <span className={styles.date}>
                                {monthlyActivity?.length
                                    ? moment(monthlyActivity?.[0].createdAt).format('MMMM YYYY')
                                    : DATA_NOT_FOUND}
                                {' - '}
                                {monthlyActivity?.length
                                    ? moment(monthlyActivity?.[monthlyActivity.length - 1].createdAt).format(
                                          'MMMM YYYY',
                                      )
                                    : DATA_NOT_FOUND}
                            </span>
                            {!isMobile && (
                                <div className={styles.barColoringInfo}>
                                    <span className={styles.proposalsColoring}>proposals</span>
                                    <span className={styles.votesColoring}>votes</span>
                                </div>
                            )}
                        </div>
                        {!activityDataLoading && monthlyActivity.length === 0 && (
                            <TableDataNotFound forGraph removeMargins />
                        )}
                        <ProposalsBarChart data={monthlyActivity} />
                    </div>
                </div>
            </div>
        </div>
    );
};
