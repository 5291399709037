import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { TooltipWrapper } from 'components';

import { DATA_NOT_FOUND } from 'constants/dataNotFound';

import { TableHeader } from '../TableHeader';
import { TableRow } from '../TableRow';

import { useAppSelector } from 'store';
import { FormatUtils } from 'utils';

export const ActivityTable: FC = (): ReactElement => {
    const {
        organizationActivityData: {
            proposalActivity: {
                proposalParticipation,
                proposalsCount,
                proposersCount,
                successfulProposalsPercentage,
                voterParticipation,
            },
        },
    } = useAppSelector(({ organization }) => organization);

    const totalProposals = FormatUtils.formatExistingNumber(proposalsCount, 0);
    const totalProposers = FormatUtils.formatExistingNumber(proposersCount, 0);
    const formattedSuccessfulProposalsPercentage =
        FormatUtils.formatExistingNumber(successfulProposalsPercentage, 1) + '%';
    const proposalParticipationCount = !FormatUtils.checkIfDataNotExist(proposalParticipation)
        ? FormatUtils.formatNumberWithCommas(FormatUtils.convertToBigIntNumber(proposalParticipation as number), 1)
        : DATA_NOT_FOUND;
    const voterParticipationCount = !FormatUtils.checkIfDataNotExist(voterParticipation)
        ? FormatUtils.formatNumberWithCommas(FormatUtils.convertToBigIntNumber(voterParticipation as number), 1)
        : DATA_NOT_FOUND;

    return (
        <>
            <TableHeader>
                <div className={styles.proposals}>proposals</div>
                <div className={styles.creators}>proposals creators</div>
                <div className={styles.successful}>successful</div>
                <div className={styles.participation}>
                    participation %
                    <div className={styles.participationInfo}>
                        <span>proposal</span>
                        <span>voter</span>
                    </div>
                </div>
            </TableHeader>
            <TableRow>
                <div className={styles.proposals}>{totalProposals}</div>
                <div className={styles.creators}>{totalProposers}</div>
                <div className={styles.successful}>{formattedSuccessfulProposalsPercentage}</div>
                <div className={styles.participationProposal}>
                    <TooltipWrapper title={proposalParticipationCount}>
                        <span>{proposalParticipationCount}</span>
                    </TooltipWrapper>
                </div>
                <div className={styles.participationVoter}>
                    <TooltipWrapper title={voterParticipationCount}>
                        <span>{voterParticipationCount}</span>
                    </TooltipWrapper>
                </div>
            </TableRow>
        </>
    );
};
