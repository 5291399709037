import React, { ReactElement, FC } from 'react';

import styles from './styles.module.scss';

import { CarouselWrapper } from 'components';
import { OrganizationMembersTable } from './components';
import { ShareholderCard } from '../OrganizationFinance/components';

import { useAppSelector } from 'store';
import { FormatUtils } from 'utils';

import { IOrganizationTopShareholder } from 'types/interfaces';

export const OrganizationMembers: FC = (): ReactElement => {
    const { organizationTopShareholdersByHoldings, topShareholdersByHoldingsLoading } = useAppSelector(
        ({ organization }) => organization,
    );

    return (
        <div className={styles.mainContainer}>
            <div className={styles.carousel}>
                <CarouselWrapper
                    data={organizationTopShareholdersByHoldings}
                    loading={topShareholdersByHoldingsLoading}
                    title="Top stakeholders - based on token holdings"
                    wrapperClassName={styles.carouselWrapper}
                    containerClassName={styles.stakeholdersContainer}
                >
                    {organizationTopShareholdersByHoldings.map(
                        (
                            { name, address, avatar, tokenSharesPercentage }: IOrganizationTopShareholder,
                            index: number,
                        ) => (
                            <ShareholderCard
                                key={index}
                                name={name}
                                address={address}
                                avatar={avatar}
                                percentage={FormatUtils.formatNumberWithCommas(tokenSharesPercentage || 0, 1)}
                            />
                        ),
                    )}
                </CarouselWrapper>
            </div>
            <OrganizationMembersTable />
        </div>
    );
};
