import { all, fork } from 'redux-saga/effects';

import { SagaIterator } from '@redux-saga/core';

import { organizationsSaga } from './organizations.saga';
import { peopleSaga } from './people.saga';
import { resourcesSaga } from './resources.saga';
import { organizationSaga } from './organization.saga';
import { userSaga } from './user.saga';
import { authSaga } from './auth.saga';
import { feedSaga } from './feed.saga';
import { searchSaga } from './search.saga';
import { socialFeaturesSaga } from './socialFeatures.saga';
import { feedbackSaga } from './feedback.saga';
import { faqsSaga } from './faqs.saga';
import { feedProposaSaga } from './feedProposal.saga';
import { selfListingSaga } from './selfListing.saga';
import { PremiumClientsSaga } from './premiumClients.saga';
import { PremiumPlansSaga } from './premiumPlans.saga';
import { PremiumSubscriptionsSaga } from './premiumSubscription.saga';
import { OrganizationsSearchSaga } from './organizationsSearch.saga';
import { daoTreasuryInvestmentsSaga } from './daoTreasuryInvestments.saga';
import { daoTokensSaga } from './daoTokens.saga';

export const rootSaga = function* (): SagaIterator {
    yield all([
        fork(socialFeaturesSaga),
        fork(organizationsSaga),
        fork(daoTokensSaga),
        fork(peopleSaga),
        fork(resourcesSaga),
        fork(organizationSaga),
        fork(userSaga),
        fork(feedSaga),
        fork(authSaga),
        fork(searchSaga),
        fork(feedbackSaga),
        fork(faqsSaga),
        fork(feedProposaSaga),
        fork(selfListingSaga),
        fork(PremiumClientsSaga),
        fork(PremiumPlansSaga),
        fork(PremiumSubscriptionsSaga),
        fork(OrganizationsSearchSaga),
        fork(daoTreasuryInvestmentsSaga),
    ]);
};
