export enum COMMON_TABLE_FIELD_TYPES {
    COMMON_TEXT = 'commonText',
    BOLD_TEXT = 'boldText',
    EXTERNAL_LINK = 'externalLink',
    INNER_LINK = 'innerLink',
}

interface ICommonTableHeaderWidth {
    desktop: string;
}

interface IRowCellLinkItems {
    title: string;
    link: string;
}

interface IRowCellLinkData {
    linkData: IRowCellLinkItems;
}

interface ICommonTableHeaderSettings {
    width: ICommonTableHeaderWidth;
}

interface ICommonTableHeader<T, K> {
    title: string;
    rowFieldKey: keyof T;
    sortedParamName?: K;
    fieldType: COMMON_TABLE_FIELD_TYPES;
    settings: ICommonTableHeaderSettings;
}

export type { ICommonTableHeader, ICommonTableHeaderSettings, IRowCellLinkData, IRowCellLinkItems };
