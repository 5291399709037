import { call, takeLatest, put, select } from 'redux-saga/effects';

import { SagaIterator } from '@redux-saga/core';

import { OrganizationActions } from 'store/actions';
import { OrganizationApi } from 'store/apis';
import { FormatUtils } from 'utils';

import { DATA_NOT_FOUND } from 'constants/dataNotFound';

import {
    ICoalitionItems,
    ICoalitionMemberData,
    ICreateDiscussionAction,
    ICreateDiscussionVoteAction,
    IDiscussionItems,
    IGetDaoDataAction,
    IGetOrganizationAction,
    IGetOrganizationLoggedData,
    IOrganizationDecision,
    IGetDiscussionAction,
    IDicussionComment,
    ICreateDiscussionCommentAction,
    ICreateDiscussionCommentVoteAction,
    IGetOrganizationIsFollowingDataPayload,
    IFollowUnfollowOrganizationPayload,
    IOrganizationMemberData,
    IOrganizationCurrency,
} from 'types/interfaces';

function* getOrganizationDataWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(OrganizationApi.getOrganizationData, payload.organizationId);

        if (hasError) {
            yield put(OrganizationActions.getOrganizationData.failure(error));
        } else
            yield put(
                OrganizationActions.getOrganizationData.success({
                    organizationData: data?.data,
                }),
            );
    } catch (e) {
        yield put(OrganizationActions.getOrganizationData.failure(e));
    }
}

function* getOrganizationSimilarDaosWorker({ payload }: IGetOrganizationAction) {
    const { hasError, error, data } = yield call(OrganizationApi.getOrganizationSimilarDaos, payload.organizationId);

    if (hasError) {
        yield put(OrganizationActions.getOrganizationSimilarDaosData.failure(error));
        return;
    }
    yield put(
        OrganizationActions.getOrganizationSimilarDaosData.success({ organizationSimilarDaos: data?.data || [] }),
    );
}

function* getOrganizationTopShareholdersByHoldingsWorker({ payload }: IGetOrganizationAction) {
    const { hasError, error, data } = yield call(
        OrganizationApi.getOrganizationTopShareholdersByHoldings,
        payload.organizationId,
    );

    if (hasError) {
        yield put(OrganizationActions.getOrganizationTopShareholdersByHoldings.failure(error));
    } else
        yield put(
            OrganizationActions.getOrganizationTopShareholdersByHoldings.success({
                organizationTopShareholdersByHoldings: data?.shareholders || [],
            }),
        );
}

function* getOrganizationTopActiveShareholdersWorker({ payload }: IGetOrganizationAction) {
    const { hasError, error, data } = yield call(
        OrganizationApi.getOrganizationTopActiveShareholders,
        payload.organizationId,
    );

    if (hasError) {
        yield put(OrganizationActions.getOrganizationTopActiveShareholders.failure(error));
    } else
        yield put(
            OrganizationActions.getOrganizationTopActiveShareholders.success({
                organizationTopActiveShareholders: data?.shareholders || [],
            }),
        );
}

function* getOrganizationAumGraphDataWorker({ payload }: IGetOrganizationAction) {
    const { hasError, error, data } = yield call(OrganizationApi.getOrganizationAumGraph, payload.organizationId);

    if (hasError) {
        yield put(OrganizationActions.getOrganizationAumGraphData.failure(error));
    } else
        yield put(
            OrganizationActions.getOrganizationAumGraphData.success({
                aumGraphData: data?.data || [],
            }),
        );
}

function* getOrganizationDaoAssetsDataWorker({ payload }: IGetOrganizationAction) {
    const { hasError, error, data } = yield call(OrganizationApi.getOrganizationAssets, payload.organizationId);

    if (hasError) {
        yield put(OrganizationActions.getOrganizationDaoAssets.failure(error));
    } else
        yield put(
            OrganizationActions.getOrganizationDaoAssets.success({
                daoAssets: data?.data || [],
            }),
        );
}

function* getOrganizationCurrenciesDataDataWorker({ payload }: IGetOrganizationAction) {
    const { hasError, error, data } = yield call(OrganizationApi.getOrganizationCurrencies, payload.organizationId);

    if (hasError) {
        yield put(OrganizationActions.getOrganizationCurrenciesData.failure(error));
    } else
        yield put(
            OrganizationActions.getOrganizationCurrenciesData.success({
                organizationCurrenciesData: data?.currencies?.length
                    ? data.currencies.map(
                          ({
                              chainId,
                              chainTitle,
                              currencyPercentage,
                              tokenName,
                              tokenAddress,
                              tokenSymbol,
                              usdValue,
                              tokenIcon,
                              tokenBalance,
                          }: IOrganizationCurrency) => ({
                              chainId,
                              chainTitle,
                              currencyPercentage: FormatUtils.checkIfDataNotExist(currencyPercentage)
                                  ? null
                                  : FormatUtils.convertToBigIntNumber(currencyPercentage as string | number),
                              tokenName,
                              tokenAddress,
                              tokenSymbol,
                              usdValue: FormatUtils.checkIfDataNotExist(usdValue)
                                  ? null
                                  : FormatUtils.convertToBigIntNumber(usdValue as string | number),
                              tokenIcon,
                              tokenBalance: FormatUtils.checkIfDataNotExist(tokenBalance)
                                  ? null
                                  : FormatUtils.convertToBigIntNumber(tokenBalance as string | number),
                          }),
                      )
                    : [],
            }),
        );
}

function* getOrganizationToolsDataWorker() {
    const { hasError, error, data } = yield call(OrganizationApi.getOrganizationTools);

    if (hasError) {
        yield put(OrganizationActions.getOrganizationToolsData.failure(error));
    } else
        yield put(
            OrganizationActions.getOrganizationToolsData.success({
                organizationTools: data?.data || [],
            }),
        );
}

function* getOrganizationProjectTokenDataWorker({ payload }: IGetOrganizationAction) {
    const { hasError, error, data } = yield call(OrganizationApi.getOrganizationProjectToken, payload.organizationId);

    if (hasError) {
        yield put(OrganizationActions.getOrganizationProjectTokenData.failure(error));
    } else
        yield put(
            OrganizationActions.getOrganizationProjectTokenData.success({
                projectToken: data?.data || {},
            }),
        );
}

function* getOrganizationDaosDataWorker({ payload }: IGetOrganizationAction) {
    const { hasError, error, data } = yield call(OrganizationApi.getOrganizationDao, payload.organizationId);

    if (hasError) {
        yield put(OrganizationActions.getOrganizationDaosData.failure(error));
    } else
        yield put(
            OrganizationActions.getOrganizationDaosData.success({
                organizationDaos: data?.data || [],
            }),
        );
}

function* getOrganizationSubstormsDataWorker({ payload }: IGetOrganizationAction) {
    const { hasError, error, data } = yield call(OrganizationApi.getOrganizationSubstorms, payload.organizationId);

    if (hasError) {
        yield put(OrganizationActions.getOrganizationSubstormsData.failure(error));
    } else
        yield put(
            OrganizationActions.getOrganizationSubstormsData.success({
                substormsList: data || [],
            }),
        );
}

function* getOrganizationDecisionsDataWorker({ payload }: IGetDaoDataAction) {
    try {
        const { data, hasError, error } = yield call(OrganizationApi.getOrganizationDecisions, payload.daoId);

        if (hasError) {
            yield put(OrganizationActions.getOrganizationDecisionsData.failure(error));
        } else {
            yield put(
                OrganizationActions.getOrganizationDecisionsData.success({
                    organizationDecisionsData: data.decisions.map(({ status, ...data }: IOrganizationDecision) => ({
                        ...data,
                        status: status && status.toLowerCase() !== DATA_NOT_FOUND ? status : null,
                        proposalStatus: status,
                    })),
                }),
            );
        }
    } catch (e) {
        yield put(OrganizationActions.getOrganizationDecisionsData.failure(e));
    }
}

function* getOrganizationMembersDataWorker({ payload }: IGetDaoDataAction) {
    try {
        const { data } = yield call(OrganizationApi.getOrganizationMembers, payload.daoId);

        yield put(
            OrganizationActions.getOrganizationMembersData.success({
                organizationMembersData: data.members?.length
                    ? data.members.map((memberData: IOrganizationMemberData, index: number) => ({
                          ...memberData,
                          name: memberData.name || memberData.address,
                          id: index,
                          rank: memberData.rank,
                      }))
                    : [],
            }),
        );
    } catch (e) {
        yield put(OrganizationActions.getOrganizationMembersData.failure(e));
    }
}

function* getOrganizationCoalitionsDataWorker({ payload }: IGetOrganizationAction) {
    try {
        const { hasError, error, data } = yield call(OrganizationApi.getOrganizationCoalitions, payload.organizationId);

        if (hasError) {
            yield put(OrganizationActions.getOrganizationCoalitionsData.failure(error));
            return;
        }

        const organizationCoalitionsData =
            data.data?.map(({ addresses, votedTogether, totalNumberOfProposals }: ICoalitionItems) => {
                const percentOfAllProposals = (votedTogether / totalNumberOfProposals) * 100;

                return {
                    members: addresses,
                    timesVotedTogether: votedTogether,
                    percentOfAllProposals,
                    names: addresses
                        .map((memberData: ICoalitionMemberData) => memberData.name || memberData.address)
                        .join(''),
                };
            }) || [];

        yield put(
            OrganizationActions.getOrganizationCoalitionsData.success({
                organizationCoalitionsData,
            }),
        );
    } catch (e) {
        yield put(OrganizationActions.getOrganizationCoalitionsData.failure(e));
    }
}

function* getOrganizationActivityDataWorker({ payload }: IGetOrganizationAction) {
    try {
        const {
            data: { data },
        } = yield call(OrganizationApi.getOrganizationActivity, payload.organizationId);

        yield put(OrganizationActions.getOrganizationActivityData.success({ organizationActivityData: data }));
    } catch (e) {
        yield put(OrganizationActions.getOrganizationActivityData.failure(e));
    }
}

function* createDiscussionWorker({ payload }: ICreateDiscussionAction) {
    try {
        const { organizationDiscussions } = yield select(({ organization }) => organization);
        const { data, hasError, error } = yield call(OrganizationApi.createDiscussion, payload);

        if (hasError) {
            if (error.statusCode === 403)
                yield put(OrganizationActions.createDiscussion.failure({ discussionCreatingError: true }));
            else yield put(OrganizationActions.createDiscussion.failure({ discussionsError: true }));
        }

        yield put(
            OrganizationActions.createDiscussion.success(
                hasError ? organizationDiscussions : [data, ...organizationDiscussions],
            ),
        );

        yield put({
            type: OrganizationActions.TOGGLE_DISSCUSION_MODAL_OPEN,
            payload: {
                discussionForEditingId: null,
                brainstormActionType: null,
            },
        });
    } catch (e) {
        yield put(OrganizationActions.createDiscussion.failure());
    }
}

function* createDiscussionVoteWorker({ payload }: ICreateDiscussionVoteAction) {
    try {
        const { organizationDiscussions, organizationDiscussion, discussionsIdsWithVoteError } = yield select(
            ({ organization }) => organization,
        );
        const { data, hasError, error } = yield call(OrganizationApi.createDiscussionVote, {
            id: payload.discussionId,
            organizationId: payload.organizationId,
            data: payload.data,
        });
        if (hasError) {
            if (error.statusCode === 403)
                yield put(
                    OrganizationActions.createDiscussionVote.failure({
                        discussionsIdsWithVoteError: [...discussionsIdsWithVoteError, payload.discussionId],
                    }),
                );
            else yield put(OrganizationActions.createDiscussionVote.failure({ discussionsError: true }));
        } else {
            const updatedDiscussion = data[0] || {};

            if (payload.isDiscussionSingle) {
                yield put(
                    OrganizationActions.getOrganizationDiscussion.success({
                        ...organizationDiscussion,
                        voted: updatedDiscussion.voted,
                        tokensAgainst: updatedDiscussion.tokensAgainst,
                        tokensFor: updatedDiscussion.tokensFor,
                        votingAgainst: updatedDiscussion.votingAgainst,
                        votingFor: updatedDiscussion.votingFor,
                        tokenVoters: updatedDiscussion.tokenVoters,
                    }),
                );
            } else {
                const updatedOrganizationDiscussions = organizationDiscussions.map((discussion: IDiscussionItems) =>
                    discussion.id === payload.discussionId
                        ? {
                              ...discussion,
                              voted: updatedDiscussion.voted,
                              tokensAgainst: updatedDiscussion.tokensAgainst,
                              tokensFor: updatedDiscussion.tokensFor,
                              votingAgainst: updatedDiscussion.votingAgainst,
                              votingFor: updatedDiscussion.votingFor,
                              tokenVoters: updatedDiscussion.tokenVoters,
                          }
                        : discussion,
                );
                yield put(OrganizationActions.createDiscussionVote.success(updatedOrganizationDiscussions));
            }
        }
    } catch (e) {
        yield put(OrganizationActions.createDiscussionVote.failure(e));
    }
}

function* createDiscussionCommentWorker({ payload }: ICreateDiscussionCommentAction) {
    try {
        const {
            organizationDiscussions,
            organizationDiscussion,
            discussionsIdsWithCommentError,
            commentsIdsWithReplyError,
        } = yield select(({ organization }) => organization);

        const { data, hasError, error } = yield call(OrganizationApi.createDiscussionComment, {
            id: payload.id,
            organizationId: payload.organizationId,
            data: payload.data,
        });

        if (hasError) {
            if (error.statusCode === 403) {
                if (payload.type === 'comment')
                    yield put(
                        OrganizationActions.createDiscussionComment.failure({
                            discussionsIdsWithCommentError: [...discussionsIdsWithCommentError, payload.discussionId],
                        }),
                    );
                else
                    yield put(
                        OrganizationActions.createDiscussionComment.failure({
                            commentsIdsWithReplyError: [...commentsIdsWithReplyError, payload.id],
                        }),
                    );
            } else yield put(OrganizationActions.createDiscussionComment.failure({ discussionsError: true }));
        } else {
            const commentData = { ...data, comments: [] };

            if (payload.isDiscussionSingle) {
                let commentList = organizationDiscussion.comments || [];

                if (payload.type === 'reply') {
                    commentList = commentList.map((comment: IDicussionComment) => {
                        const repliesList = comment.comments || [];

                        return comment.id === payload.id
                            ? {
                                  ...comment,
                                  comments: [...repliesList, commentData],
                              }
                            : comment;
                    });
                }

                const updatedDiscussion = {
                    ...organizationDiscussion,
                    comments: payload.type === 'reply' ? commentList : [...commentList, commentData],
                    countOfComments:
                        payload.type === 'comment'
                            ? organizationDiscussion.countOfComments
                                ? Number(organizationDiscussion.countOfComments) + 1
                                : 1
                            : organizationDiscussion.countOfComments,
                };

                yield put(OrganizationActions.getOrganizationDiscussion.success(updatedDiscussion));
            } else {
                const updatedOrganizationDiscussions = organizationDiscussions.map((discussion: IDiscussionItems) => {
                    let commentList = discussion.comments || [];

                    if (payload.type === 'reply') {
                        commentList = commentList.map((comment: IDicussionComment) => {
                            const repliesList = comment.comments || [];

                            return comment.id === payload.id
                                ? {
                                      ...comment,
                                      comments: [...repliesList, commentData],
                                  }
                                : comment;
                        });
                    }

                    return discussion.id === payload.discussionId
                        ? {
                              ...discussion,
                              comments: payload.type === 'reply' ? commentList : [...commentList, commentData],
                              countOfComments:
                                  payload.type === 'comment'
                                      ? discussion.countOfComments
                                          ? Number(discussion.countOfComments) + 1
                                          : 1
                                      : discussion.countOfComments,
                          }
                        : discussion;
                });

                yield put(OrganizationActions.createDiscussionComment.success(updatedOrganizationDiscussions));
            }
        }
    } catch (e) {
        yield put(OrganizationActions.createDiscussionComment.failure(e));
    }
}

function* getOrganizationBalanceWorker({ payload }: IGetOrganizationLoggedData) {
    try {
        if (payload.isLogged) {
            const { hasError } = yield call(OrganizationApi.getOrganizationBalance, payload.organizationId);

            yield put(OrganizationActions.getOrganizationBalance.success(hasError ? false : true));
        } else {
            yield put(OrganizationActions.getOrganizationBalance.success(false));
        }
    } catch (e) {
        yield put(OrganizationActions.getOrganizationBalance.failure(e));
    }
}

function* getOrganizationDiscussionsWorker({ payload }: IGetDiscussionAction) {
    try {
        const { data, hasError } = yield call(OrganizationApi.getOrganizationDiscussions, { ...payload });

        if (hasError) yield put(OrganizationActions.getOrganizationDiscussions.failure());
        else yield put(OrganizationActions.getOrganizationDiscussions.success(data?.data || []));
    } catch (e) {
        yield put(OrganizationActions.getOrganizationDiscussions.failure(e));
    }
}

function* getUserOrganizationRoleWorker({ payload }: IGetOrganizationLoggedData) {
    try {
        if (payload.isLogged) {
            const { data } = yield call(OrganizationApi.getUserOrganizationRole, payload.organizationId);

            yield put(
                OrganizationActions.getUserOrganizationRole.success({
                    userOrganizationRole: data?.[0]?.role || data?.role || null,
                }),
            );
        } else {
            yield put(OrganizationActions.getUserOrganizationRole.success(null));
        }
    } catch (e) {
        yield put(OrganizationActions.getUserOrganizationRole.failure(e));
    }
}

function* getOrganizationMembersWorker({ payload }: { payload: { search: string; organizationId: string } }) {
    try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { data } = yield call(OrganizationApi.getOrganizationDiscussionsMembers, payload);

        yield put(OrganizationActions.getUserOrganizationDiscussionsMembers.success(data || []));
    } catch (e) {
        yield put(OrganizationActions.getUserOrganizationDiscussionsMembers.failure(e));
    }
}

function* banDiscussionElementWorker({
    payload: { organizationId, discussionId, commentId, replyId, dataForBan, type },
}: any): SagaIterator {
    try {
        const { organizationDiscussions } = yield select(({ organization }) => organization);
        const { hasError } = yield call(OrganizationApi.banDiscussionElement, {
            organizationId,
            elementId: replyId || commentId || discussionId,
            dataForBan,
        });

        let updatedDiscussionData = [];

        if (hasError) {
            updatedDiscussionData = organizationDiscussions;
            yield put(OrganizationActions.banDiscussionElement.failure());
        } else {
            if (type === 'discussion') {
                updatedDiscussionData = organizationDiscussions.filter(
                    (discussion: IDiscussionItems) => discussionId !== discussion.id,
                );
            } else if (type === 'comment') {
                updatedDiscussionData = organizationDiscussions.map((discussion: IDiscussionItems) => {
                    if (discussionId === discussion.id) {
                        const commentsData = discussion.comments?.filter(
                            (comment: IDicussionComment) => comment.id !== commentId,
                        );

                        return { ...discussion, comments: commentsData };
                    } else {
                        return discussion;
                    }
                });
            } else if (type === 'reply') {
                updatedDiscussionData = organizationDiscussions.map((discussion: IDiscussionItems) => {
                    if (discussionId === discussion.id) {
                        const commentsData = discussion.comments?.map((comment: IDicussionComment) => {
                            if (comment.id === commentId) {
                                const repliesData = comment.comments.filter(
                                    (reply: IDicussionComment) => reply.id !== replyId,
                                );

                                return { ...comment, comments: repliesData };
                            } else return comment;
                        });

                        return { ...discussion, comments: commentsData };
                    } else {
                        return discussion;
                    }
                });
            }
        }

        yield put(OrganizationActions.banDiscussionElement.success(updatedDiscussionData));
    } catch (e) {
        yield put(OrganizationActions.banDiscussionElement.failure(e));
    }
}

function* getOrganizationDiscussionWorker({ payload }: any) {
    try {
        const { data } = yield call(
            OrganizationApi.getOrganizationDiscussion,
            payload.organizationId,
            payload.brainstormId,
            payload.address,
        );

        yield put(OrganizationActions.getOrganizationDiscussion.success(data || {}));
    } catch (e) {
        yield put(OrganizationActions.getOrganizationDiscussion.failure(e));
    }
}

function* updateOrganizationDiscussionWorker({ payload }: any) {
    try {
        const { organizationDiscussions } = yield select(({ organization }) => organization);
        const { data, hasError } = yield call(
            OrganizationApi.updateOrganizationDiscussion,
            payload.organizationId,
            payload.discussionId,
            payload.data,
        );

        if (hasError) {
            yield put(OrganizationActions.updateOrganizationDiscussion.failure());
        } else {
            const updatedDiscussions = organizationDiscussions.map((discussion: IDiscussionItems) =>
                discussion.id === payload.discussionId ? data : discussion,
            );

            yield put(OrganizationActions.updateOrganizationDiscussion.success(updatedDiscussions));
        }

        yield put({
            type: OrganizationActions.TOGGLE_DISSCUSION_MODAL_OPEN,
            payload: {
                discussionForEditingId: null,
                brainstormActionType: null,
            },
        });
    } catch (e) {
        yield put(OrganizationActions.updateOrganizationDiscussion.failure(e));
    }
}

function* createDiscussionCommentVoteWorker({ payload }: ICreateDiscussionCommentVoteAction) {
    try {
        const { organizationDiscussions, organizationDiscussion, commentsIdsWithVoteError } = yield select(
            ({ organization }) => organization,
        );
        const { data, hasError, error } = yield call(OrganizationApi.createDiscussionVote, {
            id: payload.commentId,
            organizationId: payload.organizationId,
            data: payload.data,
        });

        if (hasError) {
            if (error.statusCode === 403)
                yield put(
                    OrganizationActions.createDiscussionCommentVote.failure({
                        commentsIdsWithVoteError: [...commentsIdsWithVoteError, payload.commentId],
                    }),
                );
            else yield put(OrganizationActions.createDiscussionCommentVote.failure({ discussionsError: true }));
        } else {
            const updatedDiscussion = data[0] || {};
            if (payload.isDiscussionSingle) {
                yield put(
                    OrganizationActions.getOrganizationDiscussion.success({
                        ...organizationDiscussion,
                        comments: organizationDiscussion.comments.map((commentData: IDicussionComment) =>
                            commentData.id === payload.commentId
                                ? {
                                      ...commentData,
                                      voted: updatedDiscussion.voted,
                                      tokensAgainst: updatedDiscussion.tokensAgainst,
                                      tokensFor: updatedDiscussion.tokensFor,
                                      votingAgainst: updatedDiscussion.votingAgainst,
                                      votingFor: updatedDiscussion.votingFor,
                                      tokenVoters: updatedDiscussion.tokenVoters,
                                  }
                                : commentData,
                        ),
                    }),
                );
            } else {
                const updatedOrganizationDiscussions = organizationDiscussions.map((discussion: IDiscussionItems) =>
                    discussion.id === payload.discussionId
                        ? {
                              ...discussion,
                              comments: discussion.comments.map((commentData: IDicussionComment) =>
                                  commentData.id === payload.commentId
                                      ? {
                                            ...commentData,
                                            voted: updatedDiscussion.voted,
                                            tokensAgainst: updatedDiscussion.tokensAgainst,
                                            tokensFor: updatedDiscussion.tokensFor,
                                            votingAgainst: updatedDiscussion.votingAgainst,
                                            votingFor: updatedDiscussion.votingFor,
                                            tokenVoters: updatedDiscussion.tokenVoters,
                                        }
                                      : commentData,
                              ),
                          }
                        : discussion,
                );

                yield put(OrganizationActions.createDiscussionCommentVote.success(updatedOrganizationDiscussions));
            }
        }
    } catch (e) {
        yield put(OrganizationActions.createDiscussionCommentVote.failure());
    }
}

function* getOrganizationIsFollowingDataWorker({ payload }: IGetOrganizationIsFollowingDataPayload): SagaIterator {
    try {
        if (payload.isLogged) {
            const { data, hasError } = yield call(
                OrganizationApi.getOrganizationIsFollowingData,
                payload.organizationId,
            );

            if (hasError) yield put(OrganizationActions.getOrganizationIsFollowingData.failure());
            else
                yield put(
                    OrganizationActions.getOrganizationIsFollowingData.success({
                        isOrganizationFollowing: data.isFollowing,
                    }),
                );
        } else
            yield put(OrganizationActions.getOrganizationIsFollowingData.success({ isOrganizationFollowing: false }));
    } catch (e) {
        yield put(OrganizationActions.getOrganizationIsFollowingData.failure(e));
    }
}

function* followUnfollowOrganizationWorker({ payload }: IFollowUnfollowOrganizationPayload): SagaIterator {
    try {
        const { hasError } = yield call(
            payload.isOrganizationFollowing ? OrganizationApi.unFollowOrganization : OrganizationApi.followOrganization,
            payload.organizationId,
        );

        if (hasError) yield put(OrganizationActions.followUnfollowOrganization.failure());
        else
            yield put(
                OrganizationActions.followUnfollowOrganization.success({
                    isOrganizationFollowing: !payload.isOrganizationFollowing,
                }),
            );
    } catch (e) {
        yield put(OrganizationActions.followUnfollowOrganization.failure(e));
    }
}

export const organizationSaga = function* (): SagaIterator {
    yield takeLatest(OrganizationActions.GET_ORGANIZATION_DATA, getOrganizationDataWorker);
    yield takeLatest(OrganizationActions.GET_ORGANIZATION_SIMILAR_DAOS_DATA, getOrganizationSimilarDaosWorker);
    yield takeLatest(
        OrganizationActions.GET_ORGANIZATION_TOP_SHAREHOLDERS_BY_HOLDINGS,
        getOrganizationTopShareholdersByHoldingsWorker,
    );
    yield takeLatest(
        OrganizationActions.GET_ORGANIZATION_TOP_ACTIVE_SHAREHOLDERS,
        getOrganizationTopActiveShareholdersWorker,
    );
    yield takeLatest(OrganizationActions.GET_ORGANIZATION_AUM_GRAPH_DATA, getOrganizationAumGraphDataWorker);
    yield takeLatest(OrganizationActions.GET_ORGANIZATION_DAO_ASSETS, getOrganizationDaoAssetsDataWorker);
    yield takeLatest(OrganizationActions.GET_ORGANIZATION_CURRENCIES_DATA, getOrganizationCurrenciesDataDataWorker);
    yield takeLatest(OrganizationActions.GET_ORGANIZATION_TOOLS_DATA, getOrganizationToolsDataWorker);
    yield takeLatest(OrganizationActions.GET_ORGANIZATION_PROJECT_TOKEN_DATA, getOrganizationProjectTokenDataWorker);
    yield takeLatest(OrganizationActions.GET_ORGANIZATION_DAOS_DATA, getOrganizationDaosDataWorker);
    yield takeLatest(OrganizationActions.GET_ORGANIZATION_SUBSTORMS_DATA, getOrganizationSubstormsDataWorker);
    yield takeLatest(OrganizationActions.GET_ORGANIZATION_DECISIONS_DATA, getOrganizationDecisionsDataWorker);
    yield takeLatest(OrganizationActions.GET_ORGANIZATION_MEMBERS_DATA, getOrganizationMembersDataWorker);
    yield takeLatest(OrganizationActions.GET_ORGANIZATION_COALITIONS_DATA, getOrganizationCoalitionsDataWorker);
    yield takeLatest(OrganizationActions.GET_ORGANIZATION_ACTIVITY_DATA, getOrganizationActivityDataWorker);
    yield takeLatest(OrganizationActions.CREATE_DISCUSSION, createDiscussionWorker);
    yield takeLatest(OrganizationActions.CREATE_DISCUSSION_VOTE, createDiscussionVoteWorker);
    yield takeLatest(OrganizationActions.CREATE_DISCUSSION_COMMENT, createDiscussionCommentWorker);
    yield takeLatest(OrganizationActions.GET_ORGANIZATION_BALANCE, getOrganizationBalanceWorker);
    yield takeLatest(OrganizationActions.GET_ORGANIZATION_DISCUSSIONS, getOrganizationDiscussionsWorker);
    yield takeLatest(OrganizationActions.GET_USER_ORGANIZATION_ROLE, getUserOrganizationRoleWorker);
    yield takeLatest(OrganizationActions.BAN_DISCUSSION_ELEMENT, banDiscussionElementWorker);
    yield takeLatest(OrganizationActions.GET_ORGANIZATION_DISCUSSION, getOrganizationDiscussionWorker);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield takeLatest(OrganizationActions.GET_USER_ORGANIZATION_DISCUSSIONS_MEMBERS, getOrganizationMembersWorker);
    yield takeLatest(OrganizationActions.UPDATE_ORGANIZATION_DISCUSSION, updateOrganizationDiscussionWorker);
    yield takeLatest(OrganizationActions.CREATE_DISCUSSION_COMMENT_VOTE, createDiscussionCommentVoteWorker);
    yield takeLatest(OrganizationActions.GET_ORGANIZATION_IS_FOLLOWING_DATA, getOrganizationIsFollowingDataWorker);
    yield takeLatest(OrganizationActions.FOLLOW_UNFOLLOW_ORGANIZATION, followUnfollowOrganizationWorker);
};
