import React, { FC, ReactElement, useContext } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { ORGANIZATION_DATA_TABS } from 'constants/organizationTabs';

import { OrganizationContext } from 'context/organizationContext';

export const TabsController: FC = (): ReactElement => {
    const { organizationDataTabSelected, changeOrganizationDataTab } = useContext(OrganizationContext);

    return (
        <div className={styles.tabsWrapper}>
            <div className={styles.tabsContainer}>
                {Object.entries(ORGANIZATION_DATA_TABS).map(([tabKey, tabItem]) => (
                    <div
                        onClick={() => changeOrganizationDataTab(tabKey)}
                        className={classNames(styles.tabItem, {
                            [styles.activeTab]: tabKey === organizationDataTabSelected,
                        })}
                        key={tabKey}
                    >
                        <div className={styles.itemContent}>
                            <span>{tabItem}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
