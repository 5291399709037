import React, { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

import { TooltipWrapper, ImageLoader } from 'components';

import { IMAGE_TYPES } from 'constants/imageTypes';

import { LinkUtils } from 'utils';
import { defaultUserPlaceholderImage } from 'assets';

import { ICoalitionMemberData } from 'types/interfaces';

export const MemberAddressesList: FC<ICoalitionMemberData> = ({
    address,
    name,
    avatar,
}: ICoalitionMemberData): ReactElement => {
    return (
        <div className={styles.addressContainer}>
            <ImageLoader
                src={avatar ? LinkUtils.getImageLinkFromIPFS(avatar || '') : defaultUserPlaceholderImage}
                className={styles.avatar}
                imageType={IMAGE_TYPES.USER_IMAGE}
            />
            <div className={styles.coalitionDetails}>
                <TooltipWrapper title={name}>
                    <h5 className={styles.userNameAddress}>
                        <Link to={LinkUtils.createDynamicLink(address, 'user')} className={styles.link}>
                            {name}
                        </Link>
                    </h5>
                </TooltipWrapper>
            </div>
        </div>
    );
};
