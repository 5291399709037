import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { Loader, RestrictedBanner, TableDataNotFound } from 'components';
import { AssetRowItems, TableHeader } from './components';

import { assetsMockUpData } from './mockupData';

import { useAppSelector } from 'store';

import { IAssetInfo } from 'types/interfaces';

export const AssetsTable: FC = (): ReactElement => {
    const { daoAssets, daoAssetsDataLoading, daoAssetsError } = useAppSelector(({ organization }) => organization);

    const isForbidden = daoAssetsError?.statusCode === 403;

    const tableData = isForbidden ? assetsMockUpData : daoAssets;

    return (
        <>
            {!daoAssetsDataLoading && tableData.length === 0 && (
                <TableDataNotFound forTable overflowMarginsSecondVariant />
            )}

            {daoAssetsDataLoading && tableData.length === 0 && (
                <div className={styles.loaderBox}>
                    <Loader size={40} className={styles.decisionsLoader} />
                </div>
            )}

            {tableData.length > 0 && (
                <div className={styles.tableWrapper}>
                    <div className={styles.tableContainer}>
                        {isForbidden && (
                            <RestrictedBanner
                                title="DAO metadata is a Premium DeepDAO feature"
                                descriptions={[
                                    'Available only for DAO Analytics subscribers.',
                                ]}
                                connectWalletButtonTitle="Subscribe Here"
                            />
                        )}

                        <TableHeader />

                        {tableData.map((assetItems: IAssetInfo) => (
                            <AssetRowItems
                                key={assetItems.address + assetItems.url + assetItems.description}
                                {...assetItems}
                            />
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};
